<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Lomakkeen yhteydenotot</div>

    <v-card class="mt-2">
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              :label="getSearchLabels"
              single-line
              hide-details
              solo
              append-icon="mdi-magnify"
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-1" dense>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-autocomplete
              :value="selectedForm"
              :items="activeForms"
              clearable
              item-value="_id"
              item-text="name"
              label="Lomake"
              outlined
              dense
              return-object
              @click:clear="clearResults"
              @change="setFormAndDefaultHeaders($event)"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col v-if="selectedForm" cols="12" sm="6" md="4" lg="3" xl="2">
            <v-autocomplete
              v-model="selectedApartment"
              :items="activeApartments"
              :item-text="fullAddress"
              item-value="_id"
              label="Vuokrakohde"
              no-data-text="Vuokrakohteen osoite"
              outlined
              dense
              small-chips
              deletable-chips
              return-object
              @input.native="getApartments"
              @change="handleApartmentChange()"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col v-if="selectedForm" cols="12" sm="6" md="4" lg="3" xl="2">
            <v-autocomplete
              v-model="selectedRentalContract"
              :items="activeRentalContracts"
              :item-text="nameContractnumber"
              item-value="_id"
              label="Liittyy vuokrasopimukseen"
              no-data-text="Vuokrasopimuksen numero tai vuokralainen"
              outlined
              dense
              small-chips
              return-object
              deletable-chips
              @change="handleRentalContractChange()"
              @input.native="getRentalContracts"
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col v-if="selectedForm" cols="12" sm="6" md="5" lg="5" xl="5">
            <v-select
              v-model="selectedHeaders"
              :items="formHeaders"
              label="Näytä kentät"
              outlined
              dense
              multiple
              hide-details
            >
            </v-select>
            <v-btn
              v-if="selectedHeaders.length"
              class="mt-1"
              color="primary"
              small
              @click="saveDefaultHeaders()"
              >Tallenna oletuskentät</v-btn
            >
          </v-col>
        </v-row>
      </v-container>

      <!-- TABLE -->
      <v-data-table
        :headers="tableHeaders"
        :items="formattedSubmissions"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <!-- Menu -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined small icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="isAuthorized('creditReport', 'create')"
                  @click="
                    setSelectedFormSubmission(item);
                    creditReportDialog = true;
                  "
                >
                  <v-list-item-title>Tarkista luottotiedot</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="isAuthorized('tenant', 'create')"
                  @click="
                    setSelectedFormSubmission(item);
                    addToTenantDialog = true;
                  "
                >
                  <v-list-item-title>Lisää vuokralaiseksi</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-icon
              v-if="isAuthorized('formSubmission', 'read')"
              title="Esikatsele ja muokkaa"
              small
              @click="openPreviewDialog(item)"
              >mdi-eye-outline</v-icon
            >

            <v-icon
              v-if="isAuthorized('formSubmission', 'read')"
              title="Lataa pdf"
              small
              @click="
                setSelectedFormSubmission(item);
                generatePdf(item);
              "
              >mdi-download</v-icon
            >

            <v-icon
              v-if="isAuthorized('formSubmission', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>

        <template #no-data>
          <h2 v-if="totalLength == 0 || !totalLength">Ei täytettyjä lomakkeita</h2>
          <h2 v-else-if="totalLength > 0">Valitse sarakkeet nähdäksesi lomakkeet</h2>
        </template>
      </v-data-table>

      <!-- Preview submission dialog -->
      <preview-submission v-model="previewDialog"></preview-submission>
      <!-- Credit report dialog -->
      <credit-report v-model="creditReportDialog"></credit-report>
      <!-- Add to tenant dialog -->
      <add-to-tenant v-model="addToTenantDialog"></add-to-tenant>
    </v-card>

    <!-- Download loader -->
    <download-loader
      v-model="showDownloadLoader"
      :loading="downloading"
      text="Odota, ladataan pdf-tiedostoa..."
    ></download-loader>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import PreviewSubmission from "@/components/FormSubmission/PreviewSubmission";
import CreditReport from "@/components/FormSubmission/CreditReport";
import AddToTenant from "@/components/FormSubmission/AddToTenant";
import { mapActions, mapState, mapMutations } from "vuex";
import { debounce } from "../../utils/helpers";
import globalValues from "@/configs/globalValues";
import { apiAgent } from "../../services/apiAgent";
import DownloadLoader from "@/components/DownloadLoader.vue";

export default {
  title: "Lomakkeen yhteydenotot",

  mixins: [mixins],

  components: {
    PreviewSubmission,
    CreditReport,
    AddToTenant,
    DownloadLoader,
  },

  data: () => ({
    loading: false,
    creditReportDialog: false,
    addToTenantDialog: false,
    options: {},
    previewDialog: false,
    search: "",
    apartmentInput: "",
    rentalContractInput: "",
    globalValues,
    showDownloadLoader: false,
    downloading: false,
  }),

  created() {
    this.init();
  },

  computed: {
    ...mapState("formSubmission", ["totalLength"]),
    ...mapState("form", ["activeForms", "selectedForm"]),
    ...mapState("apartment", ["activeApartments"]),
    ...mapState("contract", ["activeRentalContracts"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    getSearchLabels() {
      if (this.selectedForm) {
        const metadata = this.$store.state.form.selectedForm.metadata;
        let labels = "";
        Object.keys(metadata).forEach((key, index) => {
          if (
            metadata[key].type == "string" ||
            metadata[key].type == "number" ||
            metadata[key].type == "array"
          ) {
            if (index < Object.keys(metadata).length - 1) labels += key + ", ";
            else labels += key;
          }
        });
        return labels;
      } else {
        return "Valitse ensin lomake hakeaksesi...";
      }
    },

    selectedApartment: {
      get() {
        return this.$store.state.form.selectedApartment;
      },
      set(val) {
        this.setSelectedApartment(val);
      },
    },

    selectedRentalContract: {
      get() {
        return this.$store.state.form.selectedRentalContract;
      },
      set(val) {
        this.setSelectedRentalContract(val);
      },
    },

    formHeaders() {
      if (this.selectedForm) {
        return [
          "Kohde",
          "Sopimus",
          "Luotu",
          ...Object.keys(this.$store.state.form.selectedForm.metadata),
        ];
      }
      return [];
    },

    selectedHeaders: {
      get() {
        return this.$store.state.form.selectedHeaders;
      },
      set(val) {
        this.setSelectedHeaders(val);
      },
    },

    tableHeaders() {
      const tableHeaders = this.$store.state.form.selectedHeaders.map((header) => {
        if (header == "Luotu") {
          return {
            text: header,
            value: "createdAt",
          };
        } else if (header == "Kohde") {
          return {
            text: header,
            value: "apartment",
          };
        } else if (header == "Sopimus") {
          return {
            text: header,
            value: "rentalContract",
          };
        } else {
          return {
            text: header,
            value: `fields.${header}`,
          };
        }
      });

      if (tableHeaders.length) {
        tableHeaders.push({ text: "Toiminnot", value: "actions", sortable: false });
      }
      return tableHeaders;
    },

    formattedSubmissions() {
      const formSubmissions = this.$store.state.formSubmission.formSubmissions;
      const selectedHeaders = this.$store.state.form.selectedHeaders;
      const selectedForm = this.$store.state.form.selectedForm;

      if (this.selectedForm && selectedHeaders.length > 0) {
        // Create array of formatted submissions
        return formSubmissions.map((submission) => {
          const formattedSubmission = { fields: {} };

          selectedHeaders.forEach((header) => {
            // Added headers to formHeaders
            if (header === "Luotu") {
              formattedSubmission["createdAt"] = this.formatDate(submission.createdAt);
            } else if (header === "Kohde") {
              if (submission.rentalContract?.apartment?.id) {
                formattedSubmission["apartment"] = this.fullAddress(
                  submission.rentalContract.apartment.id
                );
              } else {
                formattedSubmission["apartment"] = this.fullAddress(submission.apartment);
              }
            } else if (header == "Sopimus") {
              formattedSubmission["rentalContract"] =
                submission.rentalContract?.contractNumber || "";
            } else {
              // Other fields
              if (header in selectedForm.metadata) {
                const fieldType = selectedForm.metadata[header].fieldType;
                const value = submission.fields[header];

                if (fieldType === "date") {
                  formattedSubmission["fields"][header] = this.formatDate(value);
                } else if (fieldType === "checkbox") {
                  formattedSubmission["fields"][header] = this.formatBoolean(value);
                } else {
                  formattedSubmission["fields"][header] = value;
                }
              }
            }
            // Set id
            formattedSubmission["_id"] = submission._id;
            formattedSubmission["item"] = submission;
          });
          return formattedSubmission;
        });
      }
      return [];
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        if (this.selectedForm) this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },

    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
    }, 1000),

    rentalContractInput: debounce(function (newVal) {
      this.searchContract(`/api/v1/rental-contract/search-rental-contract?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("form", ["getActiveForms", "saveDefaultHeaders"]),
    ...mapActions("formSubmission", [
      "getFormSubmissionsByFormId",
      "deleteSubmission",
      "getFormSubmissionImages",
    ]),
    ...mapMutations("form", [
      "setSelectedForm",
      "setSelectedApartment",
      "setSelectedRentalContract",
      "setSelectedHeaders",
      "resetForms",
    ]),
    ...mapMutations("formSubmission", ["setSubmissions", "setSelectedFormSubmission"]),
    ...mapActions("apartment", ["searchApartment"]),
    ...mapActions("contract", ["searchContract"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    init() {
      this.resetForms();
      this.getActiveForms();
    },

    async getDataFromApi() {
      if (this.selectedForm && this.selectedForm._id) {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        // Save main search term
        this.saveMainSearchTerm(this.search);

        let search = this.search.trim().toLowerCase();

        const apartmentId = this.selectedApartment ? this.selectedApartment._id : "";
        const rentalContractId = this.selectedRentalContract ? this.selectedRentalContract._id : "";

        let url = `/api/v1/form-submission/get-all/${this.selectedForm._id}?apartmentId=${apartmentId}&rentalContractId=${rentalContractId}&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

        await this.getFormSubmissionsByFormId(url);

        this.loading = false;
      }
    },

    async openPreviewDialog(submission) {
      try {
        const sessionKey = submission?.item?.sessionKey || null;
        if (!sessionKey) {
          this.showPopup("Lomakkeen kuvia ei voida ladata, ota yhteyttä ylläpitoon...", "error");
        } else {
          await this.getFormSubmissionImages(sessionKey);
        }

        this.setSelectedFormSubmission({ ...submission });
        this.previewDialog = true;
      } catch (err) {
        this.showPopup(err);
      }
    },

    setFormAndDefaultHeaders(form) {
      if (form) {
        // Set form
        this.setSelectedForm(form);

        // Set default headers
        if (form.defaultHeaders && form.defaultHeaders.length) {
          let headers = [];
          for (const header of form.defaultHeaders) {
            // chack that header is in current metadata
            if (header in form.metadata) {
              headers.push(header);
            }
            // if additional headers push them too
            if (header == "Luotu" || header == "Kohde" || header == "Sopimus") headers.push(header);
          }
          this.selectedHeaders = headers;
        } else {
          this.selectedHeaders = [];
        }
        this.getDataFromApi();
      }
    },

    async getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    getRentalContracts(event) {
      this.rentalContractInput = event.target.value;
    },

    fullAddress(item) {
      if (item) {
        return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
      } else {
        return "";
      }
    },

    nameContractnumber(item) {
      if (item.tenant) {
        return item.contractNumber + ", " + item.tenant.name;
      } else {
        return item.contractNumber;
      }
    },

    clearResults() {
      this.selectedHeaders = [];
      this.setSelectedForm(null);
      this.setSubmissions({ formSubmissions: [], totalLength: 0 });
    },

    editItem(item) {
      this.$router.push("/form-submission/edit/" + item._id);
    },

    deleteItem(item) {
      if (
        confirm(
          "Haluatko varmasti poistaa yhteydenoton? Myös kuvat poistetaan, mikäli lomakkeelle on tallennettu kuvia."
        )
      )
        this.deleteSubmission({ id: item._id, sessionKey: item.item?.sessionKey });
    },

    handleApartmentChange() {
      if (this.selectedRentalContract) {
        this.selectedRentalContract = null;
      }
      this.getDataFromApi();
    },

    handleRentalContractChange() {
      if (this.selectedApartment) {
        this.selectedApartment = null;
      }
      this.getDataFromApi();
    },

    async generatePdf(item) {
      try {
        this.showDownloadLoader = true;
        this.downloading = true;
        const res = await apiAgent({
          method: "GET",
          url: `/api/v1/form-submission/download-pdf/${item._id}`,
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${this.selectedForm.name}_${this.selectedForm._id}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        this.downloading = false;
        this.showDownloadLoader = false;
      } catch (err) {
        this.downloading = false;
        this.showDownloadLoader = false;
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
